<template>
  <div class="tv pl-9 pr-9 header-clear">
    <v-row class="pb-9 wrapper">
      <h2 class="mt-12">Комбиниран</h2>
    </v-row>
    <v-row class="wrapper pb-12">
        <v-card
            class="mx-auto text-center mb-12 card card-tv is-visible"
            max-width="344"
            width="100%"
            color="#fff"
            v-for="cardCombo in cardsCombo"
            :key="cardCombo.id"
        >
            <v-card-text>
            <h4 class="card-title about-card-title mt-3">{{ cardCombo.title }}</h4>
            <div>
                <p class="display-3 black--text mb-0 mt-4">
                {{ cardCombo.tv }}
                </p>
                <p class="black--text">{{ cardCombo.sub }}</p>
            </div>
            <ul class="ul-text pa-0 mb-4 black--text">
                <li><v-icon
                right
                class="ml-0 mr-1"
                >
                mdi-check
            </v-icon>Ниски цени</li>
                <li><v-icon
                    right
                    class="ml-0 mr-1"
                >
                mdi-check
                </v-icon>Висока скорост</li>
                <li><v-icon
                    right
                    class="ml-0 mr-1"
                >
                mdi-check
                </v-icon>Надеждна връзка</li>
            </ul>
            <div class="black--text">
                <div>Цена</div>
                <span class="display-1 black--text">{{ cardCombo.price }}</span>
            </div>
            <div class="mt-6 mb-4">
                <router-link
                    outlined
                    color="#000"
                    class="text-center mb-6 tv-channels-link"
                    to="/services"
                >
                    Виж повече
                </router-link>
            </div>
            </v-card-text>
        </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
    data: () => ({
        cardsCombo: [
            { id: '7', title: 'Стандарт - Комбиниран', net: '50', tv: 'TV & NET', sub: '35 + канала', subNet: 'Mbit/s', price: '22,00 лв' },
            { id: '8', title: 'Разширен - Комбиниран', net: '80', tv: 'TV & NET', sub: '100 + канала', subNet: 'Mbit/s', price: '27,00 лв' },
            { id: '9', title: 'Разширен Плюс - Комбиниран', net: '100', tv: 'TV & NET', sub: '190 + канала', subNet: 'Mbit/s', price: '32,00 лв' },
        ],
    })
}
</script>

<style lang="scss">
    .tv-channels-link {
        font-size: 0.875rem;
        font-weight: bold;
        color: #000 !important;
        text-transform: uppercase;
        text-decoration: none;
        padding: 10px 14px;
        border: 1px solid #000;
        border-radius: 4px;
        margin-bottom: 20px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
</style>